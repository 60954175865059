#about {
    overflow-x: hidden;
    margin-top: 150px;
}

.about-title {
    margin: 0 0 16px;
    color: #ff8900;
}

#about .contents {
    line-height: 2 !important;
}

.about-content {
    margin: 0 0 24px;
}

.about-foreground {
    margin: 0;
    color: #3483C5;
}

.w-100 {
    width: 100vw;
}

#about .list {
    list-style: disc;
    padding-left: 20px;
}

#about .list>li {
    line-height: 2;
}