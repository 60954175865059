@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abel&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pathway+Gothic+One&display=swap");

html,
body {
  /* height: 100%; */
  /*外層高度100%*/
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

body > iframe {
  display: none !important;
}

ul,
li {
  margin-bottom: 0;
  padding: 0;
}

ul {
  list-style: none;
}

.wrapper {
  min-height: 100%;
  position: relative;
}

header {
  width: 100%;
  height: 1.2%;
  position: absolute;
  top: 0%;
  background: #ceb068;
  margin-bottom: 1.2;
}

footer {
  font-size: 2vmin;
  color: #0b2239;
  font-family: "Abel", sans-serif;
}

.popup-loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  background-color: rgba(gray, 0.8);
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.6s ease;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-loading.active {
  pointer-events: auto;
  opacity: 1;
  visibility: visible;
}

.popup-loading .lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.popup-loading .lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.popup-loading .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: gray;
  margin: -4px 0 0 -4px;
}

.popup-loading .lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.popup-loading .lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.popup-loading .lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.popup-loading .lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.popup-loading .lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.popup-loading .lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.popup-loading .lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.popup-loading .lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.popup-loading .lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.popup-loading .lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.popup-loading .lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.popup-loading .lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.popup-loading .lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.popup-loading .lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.popup-loading .lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.popup-loading .lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.content {
  min-height: calc(100vh - 3.2em);
  padding-bottom: 0%;
}

.page-content {
  padding: 30px 0;
  min-height: calc(100vh - 122px);
}

.bgImage {
  width: 100%;
  z-index: -999;
  position: absolute;
  bottom: 0;
  left: 0;
}

.logo {
  width: 180px;
}

.hint {
  color: #a4a4a4;
  font-weight: bolder;
}

.pb_2 {
  padding-bottom: 8px;
}

main {
  overflow-x: hidden;
  margin-top: 150px;
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}

.btn-secondary {
  background-color: #ff8900 !important;
  border-color: #ff8900 !important;
  color: #fff !important;
}

#main-navbar .line.line-top {
  background: linear-gradient(to right, #c2970c 0%, #fff7b8 50%, #c2970c 100%);
  height: 24px;
}

#main-navbar.show {
  opacity: 1;
  transition: 0.3s;
}

#main-navbar .nav-link {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #495057;
  font-weight: 500;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #c2970c;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out;
}

.lang-option {
  display: block;
  padding: 4px 16px;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  color: #495057;
}

.lang-option:hover {
  color: white;
  background: linear-gradient(to bottom, #d6d6d6 0%, #827e76);
  border-radius: 3px;
}

.g-recaptcha {
  margin-top: 40px;
}

.center-container {
  margin: 0 auto;
}

#main-navbar .nav-link:hover .link-name::after {
  background: #c2970c;
}

#main-navbar .nav-link .link-name {
  display: block;
  position: relative;
  transition: all 100ms;
  z-index: 1;
}

#main-navbar .nav-link:hover {
  color: #fff;
}

#main-navbar .nav-link .link-name::after {
  display: block;
  content: "";
  width: calc(100% + 15px);
  height: 100%;
  padding: 0 10px;
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: skewX(-35deg) translateX(-50%);
  transition: all 100ms;
}

#main-navbar .line.line-bottom {
  background: linear-gradient(to bottom, #dfc984, #d8be86);
  height: 5px;
}

#main-navbar .line {
  width: 100%;
}

#main-footer .line {
  width: 100%;
  background: linear-gradient(to right, #c2970c 0%, #fff7b8 50%, #c2970c 100%);
  text-align: center;
  padding: 8px 15px;
}

.center-container {
  border-radius: 20px;
}

.gray-background {
  background-color: #f6f6f6;
}

#inputContainer {
  position: relative;
  overflow: hidden;
}

#inputContainer .flow img {
  width: 100%;
}

.slide-container {
  display: flex;
  flex-direction: row;
}

.slide-transition {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 650px;
  overflow-wrap: break-word;
}

.slide-transition:first-of-type,
.slide-transition:last-of-type {
  align-items: start;
}

@media (max-width: 767px) {
  .slide-transition {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 350px;
    width: 450px;
    overflow-wrap: break-word;
  }
}

@keyframes out {
  0% {
    opacity: 1;
    transform: translateX(100%);
  }
  100% {
    opacity: 0;
    transform: translateX(-400%);
  }
}

@keyframes out1 {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-400%);
  }
}

@keyframes out1ToRight {
  0% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 0;
    transform: translateX(300%);
  }
}

@keyframes show0ToRight {
  0% {
    opacity: 0;
    transform: translateX(-400%);
  }
  100% {
    opacity: 1;
    transform: translateX(100%);
  }
}

@keyframes show1 {
  0% {
    opacity: 0;
    transform: translateX(300%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes show2 {
  0% {
    opacity: 0;
    transform: translateX(300%);
  }
  100% {
    opacity: 1;
    transform: translateX(-100%);
  }
}

.slide-container .isTransparent {
  opacity: 0;
  transform: translateX(300%);
}

.slide-container .show0ToRight {
  animation: show0ToRight 1s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}

.slide-container .show0 {
  transform: translateX(100%);
}

.slide-container .show1 {
  animation: show1 1s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}

.slide-container .show2 {
  animation: show2 1s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}

.slide-container .out {
  animation: out 1s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}

.slide-container .out1 {
  animation: out1 1s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}

.slide-container .out1ToRight {
  animation: out1ToRight 1s cubic-bezier(0.5, 0, 0.5, 1) forwards;
}

.emailInputComponent,
.chooseFileComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.emailInputComponent .error {
  color: red;
  font-size: 16px;
  font-weight: 500;
  padding-bottom: 8px;
}

.emailInputComponent h4 {
  font-size: 20px;
  font-weight: normal;
  color: #2a348f;
  margin: 0;
  margin-bottom: 32px;
}

.chooseFileComponent .error {
  margin-bottom: 16px;
  color: red;
  font-size: 16px;
  font-weight: 500;
}

.chooseFileComponent h6 {
  padding-bottom: 40px;
}

.verificationComponent {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #2a348f;
}

.verificationComponent h4 {
  font-size: 20px;
  font-weight: normal;
  margin: 0;
}

.verificationComponent h4 span {
  font-size: 20px;
  font-weight: bold;
  margin: 0;
}

.verificationComponent .resend {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  background-color: #2a348f;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  border: none;
}

.verificationComponent .resend.disable {
  font-size: 16px;
  font-weight: bold;
  padding: 8px 20px;
  background-color: #a9a9a9;
  color: white;
  cursor: pointer;
  border-radius: 8px;
  border: none;
}

.verificationComponent h6 {
  font-size: 16px;
  font-weight: normal;
}

.verificationComponent input {
  margin: 16px 0;
}

.verification-code-input {
  display: flex;
  margin: 20px 0;
}

.verification-code-input input {
  width: 44px;
  height: 52px;
  font-size: 20px;
  text-align: center;
  margin: 0 4px;
  border: 2px solid #eee;
  border-radius: 4px;
}

.verification-code-input input:focus {
  width: 44px;
  height: 52px;
  font-size: 20px;
  text-align: center;
  margin: 0 4px;
  border: 1px solid #2a348f;
  border-radius: 4px;
  outline: none;
}

.cantFindMail {
  margin-top: 12px;
  font-size: 18px;
  display: flex;
}

.cantFindMail span {
  font-size: 18px;
  font-weight: bold;
  text-decoration: underline;
  text-decoration-color: #2a348f;
  cursor: pointer;
}

.verificationComponent .error {
  font-size: 16px;
  margin-bottom: 8px;
  color: red;
  font-weight: bold;
}

#black-box .goBtn {
  width: 66.78px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 8%;
  border: none;
}

#black-box .upload,
#appTotalGo .upload {
  padding: 40px 32px;
}

#black-box .upload {
  width: 100%;
}

#appTotalGo .upload {
  font-size: 20px;
}

#black-box .upload .inputBox {
  display: flex;
  position: relative;
  align-items: stretch; /* 子元素高度充满容器 */
}

#black-box .upload .inputBox .uploadFileBox {
  color: white;
  font-size: 1.1em;
  position: absolute;
  width: 75%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #cdaa36;
  border-radius: 16px;
}

#black-box .upload .inputBox .choseBtn {
  cursor: pointer;
  width: 257px;
  border-radius: 16px;
}

#black-box .upload .inputBox .close-btn {
  width: 24px;
}

#black-box .upload .inputBox .close-btn:hover {
  cursor: pointer;
}

#black-box .upload .inputBox span {
  width: 78%;
  text-align: center;
}

#black-box .upload .input-group {
  margin: 16px 90px 0 90px;
  width: 70%;
}

#black-box .upload .input-group input[type="email"] {
  border: 0;
  border-radius: 0px;
  margin-left: 24px;
}

#black-box .upload .input-group input[type="button"] {
  height: 48px;
  padding: 8px 16px;
  border: 0;
  color: #fff;
  background-color: #e4cf7a;
  margin-right: 24px;
}

#black-box .upload .input-group input[type="button"].disable {
  background-color: #a9a9a9;
}

section {
  margin-top: 5vw;
}

#black-box,
#appTotalGo,
#contact {
  margin-top: 0;
}

#myChart {
  width: 100%;
  height: 280px;
}

.pieContainerStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faf9f9;
  border-radius: 8px;
  margin: 3rem;
  padding: 1rem;
}

.middle-text {
  position: absolute;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -48%);
  z-index: 2;
  color: white;
}

#pieChart {
  position: relative;
  padding: 20px 0 40px 0;
  height: 340px;
  width: 100%;
  z-index: 2;
  /* box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1); */
}

#noDataPieChart {
  position: relative;
  padding: 20px 0 40px 0;
  height: 310px;
  width: 100%;
  /* box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1); */
}
@media (max-width: 1000px) {
  #noDataPieChart {
    position: relative;
    padding: 20px 0 40px 0;
    height: 290px;
    width: 100%;
    /* box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1); */
  }
}
@media (max-width: 800px) {
  #noDataPieChart {
    position: relative;
    padding: 20px 0 40px 0;
    height: 280px;
    width: 100%;
    /* box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1); */
  }
}
@media (max-width: 767px) {
  #noDataPieChart {
    position: relative;
    padding: 20px 0 40px 0;
    height: 310px;
    width: 100%;
    /* box-shadow: 0 0 16px 8px rgba(0, 0, 0, 0.1); */
  }
}
.trapezoid-container {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.inverted-trapezoid {
  width: 20%;
  border-style: solid;
  border-width: 5px 5px 0px 5px;
  border-color: #e48500 transparent transparent transparent;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 1400px) {
  .inverted-trapezoid {
    width: 35%;
    border-style: solid;
    border-width: 5px 5px 0px 5px;
    border-color: #e48500 transparent transparent transparent;
    margin-left: auto;
    margin-right: auto;
  }
}

.trapezoid-text {
  color: #605d58;
  font-weight: bolder;
  font-size: 1.1rem;
  padding-bottom: 4px;
  white-space: pre-wrap;
  width: 100%;
  text-align: center;
}

.overlay {
  background-color: #ededed;
  opacity: 0.4;
  border-radius: 8px;
}

@media (max-width: 767px) {
  .overlay {
    background-color: #ededed;
    opacity: 0.4;
    border-radius: 40px;
  }
}

.ellipsis1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: normal;
}

.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(16, 42, 58, 0.6);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s ease;
  transition-property: opacity;
  overflow-y: auto;
  padding: 38px;
}

.modal.active {
  opacity: 1;
  visibility: visible;
  display: flex;
  pointer-events: auto;
}

.modal-container {
  width: clamp(360px, 38.8%, 560px);
  padding: 2vw;
  border-radius: 12px;
  background-color: #fafafa;
  border-radius: 16px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 32px 24px;
  width: clamp(500px, 50%, 680px);
  transition: all 0.3s ease;
  opacity: 0;
  transform-style: preserve-3d;
  transform: translateY(-80px) perspective(1000px) rotateX(30deg);
}

.modal.active .modal-container {
  transform: none;
  opacity: 1;
  transition: all 0.5s ease;
}

.modal .modal-container .text-holder {
  padding: 0.5em 2em 2em;
  font-weight: 400;
  text-align: center;
  color: #616161;
  font-size: 1.5rem;
  line-height: 1.5rem;
}

.modal-container .btn-holder {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 0;
  text-align: center;
}

.modal .modal-container .btn-holder > button {
  flex: 1;
  width: calc(30%);
  max-width: calc(30%);
  height: 2.75em;
  min-height: auto;
  min-width: auto;
  border-radius: 12px;
  color: #ffffff;
  background-color: #e4cf7a;
}

.btn:focus {
  box-shadow: 0 0 0 0;
}

#scan-total-chart canvas {
  height: 280px !important;
  width: 100% !important;
}

.toast {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
  padding: 1rem;
  color: white;
  margin: 24px;
}

.toast.sucess {
  background-color: rgba(63, 157, 63, 0.65);
}

.toast.sucess:hover {
  background-color: rgba(63, 157, 63, 1);
  cursor: pointer;
}

.toast.error {
  background-color: rgba(255, 0, 0, 0.65);
}

.toast.error:hover {
  background-color: rgba(255, 0, 0, 1);
  cursor: pointer;
}

#security-info .circle-progress-group .desc {
  word-break: break-word;
  font-size: 1em;
  line-height: 1.1em;
  font-weight: bolder;
  width: 100%;
  padding-left: 8px;
  padding-right: 8px;
}

#security-info .circle-progress-group .circle-box img {
  width: 50%;
}

#security-info .circle-progress-group .circle-progress-title {
  color: #b18b0d;
  text-align: center;
  margin-top: 8px;
  font-weight: bolder;
}

#security-info .circle-progress-group .circle-box .progress1,
#security-info .circle-progress-group .circle-box .progress2 {
  width: 100%;
  height: 100%;
}

.progress1 .CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 1px;
  stroke: #e3d297;
}

.progress2 .CircularProgressbar .CircularProgressbar-trail {
  stroke-width: 1px;
  stroke: #f87549;
}

.progress1 .CircularProgressbar .CircularProgressbar-path {
  stroke-width: 6px;
  stroke: #ccaa35;
}

.progress2 .CircularProgressbar .CircularProgressbar-path {
  stroke-width: 6px;
  stroke: #f8612e;
}

#security-info .circle-progress-group .circle-progress-num {
  top: 12%;
  left: 11%;
  width: 77%;
  height: 20%;
}

#security-info .circle-progress-group .circle-progress-num span {
  font-size: 1.7em;
  color: white;
  font-weight: bolder;
}

#security-info .circle-progress-group .circle-progress-desc {
  top: 34%;
  left: 11%;
  width: 77%;
  height: 55%;
}

#security-info .circle-progress-group .desc-box img {
  width: 100%;
}

.box {
  width: 100%;
  /* padding-top: 100%; */
  position: relative;
}

.box-in {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.box-in img {
  width: 50%;
}

.orange-circle {
  color: #a7a6a1;
}

.yellow-circle {
  color: #fef5f2;
}

#promote .row img {
  width: 100%;
}

#country .row img {
  width: 100%;
}

#country .image-container {
  padding-right: 20px;
}

.unood_ad_space_pc {
  height: 120px;
}

#check-flow .title-box .title-underline {
  display: block;
  margin: auto;
  margin-top: 30px;
}

#check-flow .list .item-pic {
  display: block;
}

#check-flow .list .item-title {
  display: block;
  position: relative;
  transition: all 100ms;
  z-index: 1;
  padding: 8px 10px;
  margin-bottom: 0;
}

#check-flow .list .item-title::before {
  content: "▶";
  position: absolute;
  font-size: 12px;
  top: calc(50% - 9px);
}

#check-flow .list .item-title::after {
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  background-color: #efc65d;
  position: absolute;
  top: 0;
  left: 50%;
  z-index: -1;
  transform: skewX(-15deg) translateX(-50%);
  transition: all 100ms;
}

#check-tools {
  background-color: #f8f9fa;
}

#check-tools table {
  box-shadow: 0px 30px 18px -20px #c5c5c5;
}

#check-tools td {
  text-align: center;
}

#check-tools td .center-ul {
  text-align: left;
  display: inline-block;
}

#check-tools th:nth-child(1) {
  width: 30%;
}

#check-tools th,
#check-tools td {
  border: 5px solid #f8f9fa;
}

#check-tools .bg-primary {
  background-color: #c2970c !important;
}

.btn-golden-gradient {
  background: linear-gradient(to bottom, #e4c400, #c2970c);
  border: 0;
  box-shadow: 0px 5px 8px -1px #b6b6c1;
}

#page-side-btns.show {
  opacity: 1;
  transition: 0.3s;
  display: block;
}

#page-side-btns #page-gotop {
  right: 32px;
  bottom: 50px;
  cursor: pointer;
  border-radius: 50%;
  position: fixed;
  color: #adb5bd;
  z-index: 500;
}

#page-side-btns #page-gotop:hover {
  color: black;
}

.error .invalid-feedback {
  display: block;
}

.error .recaptcha-checkbox-border,
.error .rc-anchor-light.rc-anchor-normal,
.error .rc-anchor-light.rc-anchor-compact {
  border-color: red;
}

.error input,
.error textarea {
  border-color: red;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.error input.form-control:focus,
.error textarea.form-control:focus {
  border-color: rgba(255, 0, 0, 0.65);
  box-shadow: 0 0 0 0.25rem rgba(255, 0, 0, 0.25);
}

@media (min-width: 768px) {
  .page-section {
    padding: 100px 0;
  }

  #check-flow .title-box .title-underline {
    width: 200px;
  }

  #check-flow .list .list-item {
    padding: 0 15px;
  }

  #check-flow .list .item-pic {
    width: 90%;
    margin-left: 12%;
  }

  #check-flow .list .item-title {
    font-size: 16px;
    width: 60%;
    margin: auto;
  }

  #check-flow .list .item-title::before {
    left: -30px;
  }

  #page-side-btns #page-gotop {
    right: 50px;
    bottom: 50px;
  }

  #page-side-btns #page-gotop .bi {
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 767.98px) {
  .page-section {
    padding: 60px 0;
  }

  #check-flow .title-box .title-underline {
    width: 160px;
  }

  #check-flow .list .list-item {
    padding: 0 10px;
  }

  #check-flow .list .item-pic {
    width: 120%;
    margin: auto;
  }

  #check-flow .list .item-title {
    font-size: 14px;
  }

  #check-flow .list .item-title::before {
    left: -20px;
  }

  #check-flow .list .list-item:nth-child(2) {
    margin-top: 180px;
  }

  #check-flow .list .list-item:nth-child(3) {
    margin-top: 360px;
  }

  #page-side-btns #page-gotop .bi {
    width: 35px;
    height: 35px;
  }
}

@media (min-width: 992px) {
  #main-navbar .nav-link {
    display: inline-block;
    padding: 5px 10px;
    color: #495057;
    text-decoration: none;
    border-radius: 3px;
    transform: 0.3s;
  }

  .lang-dropdown {
    background-color: white;
    border-radius: 12px;
    position: absolute;
    z-index: 101;
    top: 75%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  .lang-dropdown ul {
    margin: 0 !important;
    padding: 8px !important;
  }
}
